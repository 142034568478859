<template>
    <div class="content">
        <el-form :model="userForm" status-icon :rules="userReg" ref="userForm" key="userForm"
                 label-position="top" label-width="80px">
            <el-form-item label="昵称" prop="nickName">
                <el-input v-model="userForm.nickName"></el-input>
            </el-form-item>
            <el-form-item label="姓名" prop="realName">
                <el-input type="input" v-model="userForm.realName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
                <el-input type="input" v-model="userForm.phone"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('userForm')">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {MdfUser,GetUser} from '@/request/api';

    export default {
        components: {},
        data() {
            var checkMobile = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('請輸入正確的手機號'));
                }
                setTimeout(() => {
                    let mobile = /^(0\d{9,10})$/;
                    if (mobile.test(value) || value == '') {
                        callback();
                    } else {
                        callback(new Error('請輸入正確的手機號'));
                    }
                }, 500);
            };
            return {
                userForm: {
                    realName: '',
                    nickName: '',
                    phone: '',
                },
                userReg: {
                    realName: [
                        {required: false, trigger: 'blur'}
                    ],
                    nickName: [
                        {required: true, trigger: 'blur'}
                    ],
                    phone: [
                        {required: false, validator: checkMobile, trigger: 'blur'}
                    ],
                }
            }
        },
        methods: {
            initData(){
                GetUser({}).then(res => {
                    if (res.code == 200) {
                        this.userForm.realName = res.data.realName;
                        this.userForm.nickName = res.data.nickName;
                        this.userForm.phone = res.data.phone;
                        this.$store.commit('SET_USER_REALNAME', res.data.realName);
                        this.$store.commit('SET_USER_NICKNAME', res.data.nickName);
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                    this.$message.error('網路連線失敗，稍候請重試!');
                });
            },
            submitForm(formName) {
                let _this = this;
                this.$refs[formName].validate((valid) => {
                    if (!valid) {
                        this.$message.error('請輸入正確的資料');
                        return false;
                    }
                    MdfUser({
                        realName: _this.userForm.realName,
                        phone: _this.userForm.phone,
                        nickName: _this.userForm.nickName
                    }).then(res => {
                        if (res.code == 200) {//取得line授权网址并跳转
                            _this.$message.success(res.message);
                            let user=this.$store.state.user;
                            user.realName=_this.userForm.realName;
                            user.nickName=_this.userForm.nickName;
                            user.phone=_this.userForm.phone;
                            this.$store.commit('SET_USER', user);
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch(e => {
                        this.$message.error(e.message);
                        console.log(e);
                    });
                });
            }
        },
        mounted(){
            this.initData();
        },
        created() {

        }
    }
</script>
<style lang="less" scoped>
    .content {
        padding: 10px;
    }
</style>
